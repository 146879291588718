<template>

<div class="game">

	<div class="game-image" v-bgimage="game.bgg_game.image"></div>

	<div class="game-info">

		<div class="game-info-text">

			<div class="game-info-name">{{ game.name }}</div>

			<div class="game-info-version" v-if="game.version && game.year">{{ game.version }} ({{game.bgg_version.year}})</div>
			<div class="game-info-version" v-if="game.version && !game.year">{{ game.version }}</div>
			<div class="game-info-version" v-if="!game.version && game.year">{{ game.year }}</div>

		</div>

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['game']

}

</script>

<style scoped>

.game-image {
	height: 200px;
	background-color: #ccc;
	flex-shrink: 0;
	background-size: cover;
	background-position: 50% 50%;
}

.game-info {
	padding: 10px;
	flex-shrink: 0;
	display: flex;
}

.game-info-text {
	flex-grow: 1;
}

.game-info-name {
	font-weight: 400;
	font-size: 24px;
	margin-bottom: 2px;
}

.game-info-version {
	font-size: 16px;
	font-weight: 300;
	color: #333;
}

</style>
